function ShowReclameInline(props) {
  if( props.reclame.afbeelding !== undefined ) {
    return (
      <section>
        <div className="reclameBlok">
          <div className={props.reclame.isLogo === "1" ? 'img isLogo' : 'img'}>
            <img key={props.reclame.iddigitalsignage} src={"data:image/png;base64,"+ props.reclame.afbeelding} alt={props.reclame.titel} />
          </div>
        </div>
      </section>
    )
  }
}

export default ShowReclameInline