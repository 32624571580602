import React, {Component} from 'react';

class Tegel extends Component {
    
    render() {
        return <article key={"key"+this.props.evenement.idevenementactiviteit.toString()} className="tegel">
        <div className="content">
            <div className="starttijd">{this.props.evenement.starttijd_tijd}</div>
            <div className="naam">{this.props.evenement.naam} </div>
            <div className="locatie">{this.props.locaties[this.props.evenement.locatie_id]['instructie']}</div>
        </div>
        <div className="space"></div>
        <div className="icon">
            <div className="icon_icon"><img src={process.env.PUBLIC_URL +"/"+ this.props.locaties[this.props.evenement.locatie_id]['afbeelding']} alt="Naar achteren" /></div>
            <div className="icon_description">{this.props.evenement.locatie_naam}</div>            
        </div>
    </article>
}

}

export default Tegel