import DateTime from './dateTime';

function Header() {
    return (
        <>
            <div className="logo_bg">
                <img src={process.env.PUBLIC_URL + "/logo_bg.png"} alt="Logo Koningshoeve" />
            </div>          
  
            <header>
                <div className="title">Welkom bij de Koningshoeve</div>
                <div className="clock"><DateTime></DateTime></div>
            </header>
      </>
    )
  
  }

  export default Header;