import Tegel from './Tegel';

function ShowEvenementen(props) {  
  if( props.evenementen ) {
      return(
        <section>
          {props.evenementen.map(evenement => <Tegel key={"Test"+evenement.idevenementactiviteit.toString()} uniquekey={evenement.idevenementactiviteit} evenement={evenement} locaties={props.locaties} />)}
        </section>
      )
  }
}

  export default ShowEvenementen