import React, { Component } from 'react';

import './App.css';

import NoInternetConnection from './components/NoInternetConnection';
import axios from 'axios'
import Header from './components/Header';
import Footer from './components/Footer';
import ShowEvenementen from './components/ShowEvenementen';
import ShowReclameInline from './components/ShowReclameInline';
import ShowReclameFullscreen from './components/ShowReclameFullscreen';


const config = {
  headers: {
    'Pasy-Token': `Pasy20200928`
  }
};

const locaties = {
  3: {
    'afbeelding': 'Arrow_Up_Right.svg',
    'instructie': 'Garderobe boven • Lift bereikbaar via restaurant'
  },
  5: {
    'afbeelding': 'Arrow_Left.svg',
    'instructie': ''
  },
  6: {
    'afbeelding': 'Arrow_Right.svg',
    'instructie': ''
  },
  7: {
    'afbeelding': 'Arrow_Right.svg',
    'instructie': ''
  },
  33: {
    'afbeelding': 'Arrow_Right.svg',
    'instructie': ''
  },
  8: {
    'afbeelding': 'Arrow_Left.svg',
    'instructie': ''
  },
  355: {
    'afbeelding': 'Arrow_Left.svg',
    'instructie': ''
  },
  1341: {
    'afbeelding': 'Arrow_Left.svg',
    'instructie': ''
  },
  25: {
    'afbeelding': 'Arrow_Left.svg',
    'instructie': ''
  },

}

const schermConfig = {
  1: {
    'naam': 'Schuur',
    'locaties': '25, 1341, 355, 8',
    'idscherm': '1'
  },
  2: {
    'naam': 'Restaurant',
    'locaties': '3, 6, 33, 7, 5',
    'idscherm': '2'
  }
}

class App extends Component {

  interval = null;

  showEvent = false;
  showCommercial = false;
  showCommercialPrimary = false;
  scherm_id = 2;

  componentDidMount() {
    const queryParameters = new URLSearchParams(window.location.search)
    if (queryParameters.get("scherm_id") != null) {
      this.scherm_id = queryParameters.get("scherm_id");
    }

    // this.interval = setInterval(this.getData, 60000); // 60 seconds
    this.interval = setInterval(this.getData, 30000); // 30 seconds
    this.getData();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getEvenementen = () => {
    var showVan = new Date();
    var showTot = new Date();
    showVan.setHours(showVan.getHours() - 1);
    showTot.setHours(showTot.getHours() + 1);

    if( schermConfig.hasOwnProperty(this.scherm_id) ) {
      var showLocaties = schermConfig[this.scherm_id]['locaties']; // REPLACE BY SCHERMID

      axios.get(process.env.REACT_APP_API_PASY + `/index_new.php?idbedrijf=1&van=` + showVan.toLocaleString() + `&tot=` + showTot.toLocaleString() + `&locaties=` + showLocaties, config)
        .then(res => {
          if (res.status === 200) {
            this.setState({ evenementen: res.data.data });
            if (res.data.data != 0) {
              this.showEvent = true;
            } else {
              this.showEvent = false;
            }
          } else {
            this.showEvent = false;
          }
        })
        .catch(error => {
          console.log("Oh, een error", error);
        });
    }
  }

  getData = () => {

    axios.get(process.env.REACT_APP_API_SIGNAGE + `/index.php?idscherm=` + this.scherm_id).then(res => {
      console.log("API Call overview");

      if (
        (res.data.idsignageafbeelding != this.state.reclameTonen.idsignageafbeelding) ||
        (res.data.last_update != this.state.reclameTonen.last_update)
      ) {
        console.log("Afwijking geconstateerd");
        this.setState({ reclameTonen: res.data }, () => {
          // console.log("Opnieuw gezet", this.state.reclameTonen );
        });

        // Get new record
        axios.get(process.env.REACT_APP_API_SIGNAGE + `/get_signage.php?idsignageafbeelding=` + res.data.idsignageafbeelding).then(resImage => {
          console.log("API Call image");
          this.setState({ reclame: resImage.data }, () => {
            // console.log("reclame state", this.state.reclame );
          });
        });
      } // else = do nothing

      if (res.data != 0 && res.data.idsignageafbeelding !== undefined) {
        this.showCommercial = true;

        if (res.data.isPrimary !== "1") {
          this.showCommercialPrimary = false;
          this.getEvenementen();
        } else {
          this.showCommercialPrimary = true;
        }

      } else {
        this.showCommercial = false;
        this.getEvenementen();
      }

    })
  }

  state = {
    evenementen: [],
    reclameTonen: [],
    reclame: []
  }

  render() {

    return (
      <div className="App" id="content">
        <NoInternetConnection>

          {this.showCommercialPrimary === true ? (
            <>
              {this.state.reclame.fullScreen === "1" ? (
                <ShowReclameFullscreen reclame={this.state.reclame} key={this.state.reclame.idsignageafbeelding} />
              ) : (
                <>
                  <Header />
                  <ShowReclameInline reclame={this.state.reclame} key={this.state.reclame.idsignageafbeelding} />
                  <Footer toilet={this.scherm_id === "1"} />
                </>
              )}
            </>
          ) : (
            <>
              {this.showEvent === true ? (
                <>
                  <Header />
                  <ShowEvenementen evenementen={this.state.evenementen} locaties={locaties} key="Test" />
                  <Footer toilet={this.scherm_id === "1"} />
                </>
              ) : (
                <>
                  {this.showCommercial ? (
                    <>
                      {this.state.reclame.fullScreen === "1" ? (
                        <ShowReclameFullscreen reclame={this.state.reclame} key={this.state.reclame.idsignageafbeelding} />
                      ) : (
                        <>
                          <Header />
                          <ShowReclameInline reclame={this.state.reclame} key={this.state.reclame.idsignageafbeelding} />
                          <Footer toilet={this.scherm_id === "1"} />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Header />
                      <section></section>
                      <Footer toilet={this.scherm_id === "1"} />
                    </>
                  )}
                </>
              )}
            </>

          )}

        </NoInternetConnection>
      </div>
    );
  }
}

export default App;
