function ShowReclameFullscreen(props) {
  if( props.reclame.afbeelding !== undefined ) {
    return (
      <div className={"fullScreenReclame"+ props.reclame.fullScreen ? "fullScreenReclame" : ""}>
          <img key={props.reclame.iddigitalsignage} src={"data:image/png;base64,"+ props.reclame.afbeelding} alt={props.reclame.titel} />
      </div>
    )
  }
}

export default ShowReclameFullscreen