function Footer(props) {
    if( props.toilet === true) { 
        return (
        <footer>
            <article className="footerTegel">
            <div className="content">Toiletten</div>
            <div className="space"></div>
            <div className="icon"><img src={process.env.PUBLIC_URL + "/Arrow_Right_Small.svg"} alt="Pijl rechts" /></div>
            </article>
        </footer>
        )
    } else {
      return (
        <footer></footer>
      )
    }
}

export default Footer;